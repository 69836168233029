import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../common/context/AppContext";
import { usePromoListings } from "../../../common/hooks";
import { greetingsPhrase } from "../../../common/utils";
import { ExpandableRow } from "../../components/ExpandableRow";
import { Header } from "../../components/Header";
import { VideoModal } from "../../components/VideoModal";
import { FAQ_PAGE_LINK, IS_PROD } from "../../../common/constants";
import { useEffect, useState } from "react";
import { HotDealModal } from "../../components/HotDealModal";
import { TrolleyIcon } from "../../../common/assets/icons/TrolleyIcon";
import { ReferBanner } from "../../components/ReferBanner";
import { useYourAcquisitions } from "./hooks";
import { IStartup } from "../../../common/models";
import { TinyListing } from "../../components/TinyListing";
import { RoutePaths } from "../../consts/enums";
import { JOE_CALENDLY_LINK } from "../../consts/consts";

const steps = [
  {
    title: "Finding a suitable business",
    content: (
      <Box>
        <ExpandableRow
          key={"business-advice"}
          title={"Advice"}
          titleVariant="h3"
        >
          <Stack component="ul" spacing={2}>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Strategy:</Typography>
                Before carrying out a thorough screening of companies for sale,
                build out an idea of what your aim is with the acquisition. For
                example, this may be that you want to acquire a business with a
                certain area of expertise, or that you want to acquire a
                competitor to increase your market share.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Shortlist criteria:
                </Typography>
                Using your acquisition strategy, list out key criteria that a
                potential business should meet to be suitable for acquisition.
                For example, you may want to acquire a business that operates in
                a certain geographic region, or in a certain sector of the
                industry you are looking at. This will narrow down your search
                and ensure efficiency in the process.
              </Typography>
            </li>
          </Stack>
        </ExpandableRow>
      </Box>
    ),
  },
  {
    title: "Target business evaluation",
    content: (
      <Box>
        <ExpandableRow
          key={"target-glossary"}
          title={"Glossary"}
          titleVariant="h3"
        >
          <Stack component="ul" spacing={2}>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Target (seller):
                </Typography>
                A company that is interested in selling their business, or a
                portion of it.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Acquirer (or Buyer):
                </Typography>
                An individual, company, or investment group that is interested
                in purchasing another company or a portion of it.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Indication of Interest (IOI):
                </Typography>
                A written expression by the acquirer indicating a genuine
                interest in buying the target company. It typically outlines the
                valuation range, deal structure, and other key terms but is
                non-binding. It may be an informal message or discussed
                verbally.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Letter of Intent (LOI) or heads of terms:
                </Typography>
                The LOI is a letter that buyers present to sellers to express
                their formal interest in acquiring a business. It outlines the
                key terms of a deal, such as purchase price, deal structure, and
                payment terms. While usually non-binding regarding price, some
                terms, like confidentiality and exclusivity, might be.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Exclusivity (or No-shop Clause):
                </Typography>
                A provision in the LOI that prohibits the seller from
                soliciting, negotiating, or accepting other offers for a
                specified period.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Bid:</Typography>A formal
                offer made by the buyer to purchase the company. In a
                competitive sale process, multiple buyers might submit bids.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Teaser:</Typography>A
                brief, anonymous document provided to potential buyers
                highlighting the key attributes of the company without
                disclosing its identity. Its purpose is to generate interest
                among potential acquirers.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Information Memorandum (IM):
                </Typography>
                Also referred to as a CIM (Confidential Information Memorandum),
                this is a marketing document prepared by the seller that
                provides a comprehensive overview of the business, including
                sections such as the company overview, market analysis and key
                financials
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Management Presentation:
                </Typography>
                A more detailed version of an Information Memorandum that is
                tailored towards being used in a meeting where the management /
                founder presents to the potential buyer (usually used in larger
                deals)
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  NDA (Non-disclosure Agreement):
                </Typography>
                A legally binding contract requiring the buyer to maintain
                confidentiality regarding all proprietary information shared
                during the due diligence.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Virtual Data Room (VDR):
                </Typography>
                A secure online platform where sellers upload detailed
                information about their company for potential buyers to review.
                This assists buyers in making informed decisions during the due
                diligence phase.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Synergies:</Typography>
                The benefits that the acquirer believes can be achieved by
                integrating the target company into its operations. Synergies
                can be cost-related (cost savings) or revenue-related (potential
                for increased sales).
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Strategic Buyer:
                </Typography>
                An acquirer that operates in the same or complementary industry
                as the target company and seeks synergies through the
                acquisition.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Financial Buyer / Financial Sponsor:
                </Typography>
                Typically private equity firms or other investment groups that
                do not have operations related to the target. They look for
                financial returns on their investment.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Roll-up:</Typography>A
                strategy where an acquirer buys multiple smaller companies in
                the same sector to consolidate market presence and achieve
                economies of scale.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Valuation Multiples:
                </Typography>
                Financial metrics used to value a company, such as EBITDA
                multiples, which compare the company&apos;s value to its
                earnings before interest, tax, depreciation, and amortisation.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  DCF (Discounted Cash Flows):
                </Typography>
                A method of valuing a business through projecting its cashflows
                in perpetuity and discounting them to a present value to
                understand the company’s intrinsic value.
              </Typography>
            </li>
          </Stack>
        </ExpandableRow>
        <ExpandableRow key={"target-advice"} title={"Advice"} titleVariant="h3">
          <Stack component="ul" spacing={2}>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Reaching out:</Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Meeting the founder / owner:
                    </Typography>
                    <Typography variant="body2">
                      Take time in getting to know the founder, understanding
                      both the history of the business and the founder’s take on
                      how he believes the company can grow going forward.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Initial due-diligence:
                    </Typography>
                    <Typography variant="body2">
                      With some initial data points and marketing materials
                      provided by the seller such as a teaser, begin to look
                      into the company further, understanding if it is a good
                      fit for you. This includes looking at their overall
                      operations, financials and other key metrics.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Non-Disclosure Agreements (NDAs):
                    </Typography>
                    <Typography variant="body2">
                      Before the target shares any confidential information, it
                      is common practice to sign an NDA. This protects sensitive
                      data about the tech business for the seller.
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Preliminary due-diligence / evaluation:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      IM (Information Memorandum):
                    </Typography>
                    <Typography variant="body2">
                      At this stage, to help you in understanding the business,
                      you will be provided with a document by the seller which
                      goes into detail on the business, including the story,
                      team, products, market, key financials and any other
                      relevant information that would assist you in making an
                      initial bid.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Product demo:
                    </Typography>
                    <Typography variant="body2">
                      Sometimes the target business will be able to offer a
                      demonstration of their product offering, often done via a
                      video.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Business operations & financials:
                    </Typography>
                    <Typography variant="body2">
                      Analyse the key areas / metrics of the business to
                      understand its operational efficiency. This might include
                      looking at how fast it has been growing in recent years,
                      and looking into its profitability. You will want to look
                      at the balance sheet, cash flow statement and profit &
                      loss statement for both historic figures (over the past
                      few years) and business projections.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>Valuation:</Typography>
                    <Typography variant="body2">
                      To be able to make an initial offer, you first need to
                      understand what the business is worth. This can be a
                      detailed process using various valuation methodologies
                      such as building DCFs (Discounted Cash Flow models) to
                      understand the value. Consider consulting Foundy on
                      conducting a thorough business evaluation.{" "}
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>Synergies:</Typography>
                    <Typography variant="body2">
                      Within the valuation workstream, ensure to include any
                      additional value creation you believe you can bring to the
                      business. For example, you may have industry connections
                      or expertise that would propel the business faster than it
                      would otherwise, hence its value to you is higher than the
                      standalone value of the business.{" "}
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
          </Stack>
        </ExpandableRow>
      </Box>
    ),
  },

  {
    title: "Negotiation",
    content: (
      <Box>
        <ExpandableRow
          key={"negotiation-glossary"}
          title={"Glossary"}
          titleVariant="h3"
        >
          <Stack component="ul" spacing={2}>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Negotiation:</Typography>
                The process by which two or more parties discuss and eventually
                reach an agreement on the terms and conditions of a deal.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Reservation Price:
                </Typography>
                The minimum price a seller is willing to accept or the maximum
                price a buyer is willing to pay.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Term Sheet:</Typography>A
                non-binding document outlining the basic terms and conditions
                under which an investment will be made. It serves as a template
                for the parties to negotiate and draft more detailed legal
                documents.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  LOI (Letter of Intent):
                </Typography>
                A preliminary written agreement between the buyer and the
                seller, indicating the buyer&apos;s intent to purchase the
                company under specific terms.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  SPA (Sale & Purchase Agreement):
                </Typography>
                A document outlining the final terms of the sale of the company,
                including key negotiation outcomes such as the final price of
                acquisition.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Earn-Out:</Typography>A
                provision where the seller receives additional payment based on
                the acquired company&apos;s future performance.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Clawback:</Typography>A
                provision that allows the buyer to recover funds under certain
                conditions, especially if representations or warranties are
                breached.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Indemnity Clause:
                </Typography>
                A clause where one party commits to compensate the other for any
                harm, liability, or loss arising from the deal.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Deal Structure:
                </Typography>
                How the transaction is set up, which can include a mix of cash,
                stock, earn-outs, and other considerations.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Synergy Value:</Typography>
                The value created by businesses when they are combined,
                resulting from cost savings or revenue enhancements.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Due Diligence Contingency:
                </Typography>
                A clause allowing the buyer to back out of the purchase
                agreement if due diligence uncovers certain predefined issues.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Escrow:</Typography>A
                financial arrangement where a third party holds and regulates
                payment of funds, ensuring transactions are completed per the
                deal terms.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Exclusivity Period:
                </Typography>
                A timeframe during which the seller agrees not to engage in
                negotiations with any other potential buyers.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Goodwill:</Typography>
                The intangible assets of a company, such as brand reputation,
                customer relationships, and intellectual property, that are not
                easily quantifiable.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Multiples:</Typography>
                Commonly used in valuation, it represents how much an entity is
                worth as a multiple of its revenues, EBITDA, or other metrics.
              </Typography>
            </li>
          </Stack>
        </ExpandableRow>
        <ExpandableRow
          key={"negotiation-checklist"}
          title={"Checklist"}
          titleVariant="h3"
        >
          <Stack component="ul" spacing={2}>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Understand the value of the business & consider expert advice:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Technical Assets:
                    </Typography>
                    <Typography variant="body2">
                      Clearly understand and articulate the value of the
                      proprietary technologies, algorithms, and other
                      intellectual properties. What sets their technology apart?
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Data and User Base:
                    </Typography>
                    <Typography variant="body2">
                      For many tech businesses, the value isn&apos;t just in the
                      software, but in the user data, customer behaviour, and
                      network effects.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Recurring Revenue Streams:
                    </Typography>
                    <Typography variant="body2">
                      If the company operates on a subscription model (like SaaS
                      businesses), the predictable revenue streams can
                      significantly bolster the value of the business.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Third-party advice:
                    </Typography>
                    <Typography variant="body2">
                      Before starting the negotiation process, consider taking
                      on legal and financial advisors to help you. Legal counsel
                      familiar with tech M&A can assist in ensuring contracts
                      protect your interests and are free from ambiguous terms.
                      Financial advice from a knowledgeable expert can provide
                      valuable insights into structuring the deal in the most
                      advantageous way, considering taxes, future financial
                      projections, and more. Speak with us about our advisory
                      service for more information.{" "}
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Setting the Baseline & managing expectations:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Know Your Walk-Away Point:
                    </Typography>
                    <Typography variant="body2">
                      Before entering negotiations, set a ceiling value above
                      which the deal wouldn&apos;t make sense. This is crucial
                      in avoiding being cornered into disadvantageous terms.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Be Transparent About Deal-breakers:
                    </Typography>
                    <Typography variant="body2">
                      If there are certain terms or conditions you&apos;re not
                      willing to budge on, communicate them clearly from the
                      outset.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Bid deadlines:
                    </Typography>
                    <Typography variant="body2">
                      Make sure to put in your offer before any bid deadlines
                      are reached
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Stay Objective:
                    </Typography>
                    <Typography variant="body2">
                      It&apos;s natural to be emotionally attached to the
                      process. However, during negotiations, strive for
                      objectivity. Emotions can cloud judgement.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Open-minded Flexibility:
                    </Typography>
                    <Typography variant="body2">
                      While having non-negotiable points is essential, also
                      maintain flexibility. The end goal is a win-win situation,
                      and sometimes compromise can lead to a more fruitful
                      long-term relationship.{" "}
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Understand your leverage points:
                    </Typography>
                    <Box component="ul" my={2}>
                      <li>
                        <Typography sx={{ fontWeight: 600 }}>
                          Exclusivity Agreements:
                        </Typography>
                        <Typography variant="body2">
                          Often having exclusive conversations with the seller
                          makes the process easier to navigate and can lead to
                          achieving better valuation terms if there are no bids
                          from other parties.{" "}
                        </Typography>
                      </li>
                      <li>
                        <Typography sx={{ fontWeight: 600 }}>
                          Alternative bidders:
                        </Typography>
                        <Typography variant="body2">
                          Understand that there are likely other people
                          interested in the business, and who are also making
                          offers. negotiate tactically so as to get a fair
                          value, but not make your bid unattractive compared to
                          others{" "}
                        </Typography>
                      </li>
                    </Box>
                  </li>
                </Box>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  The Nuances of Tech Deals:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>Earn-outs:</Typography>
                    <Typography variant="body2">
                      Common in tech M&A, where part of the purchase price is
                      deferred and dependent on achieving certain
                      post-acquisition metrics. While they can bridge valuation
                      gaps, ensure that the terms are clear, measurable, and
                      achievable.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      IP Transfers:
                    </Typography>
                    <Typography variant="body2">
                      Intellectual property is often at the heart of tech deals.
                      Clearly understand how and when these will be transferred,
                      and the protections in place.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Employee Retention:
                    </Typography>
                    <Typography variant="body2">
                      Many tech businesses have key personnel critical to their
                      success. You might want clauses that ensure these
                      individuals stay on post-acquisition. Be prepared to
                      discuss this.
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Post-Negotiation Steps:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>LOI:</Typography>
                    <Typography variant="body2">
                      Consider drafting a Letter of Intent which details your
                      initial bid. At this stage, this document is not legally
                      binding, but expresses your interest in the business and
                      the valuation you are looking for.
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
          </Stack>
        </ExpandableRow>
      </Box>
    ),
  },

  {
    title: "Due Diligence",
    content: (
      <Box>
        <ExpandableRow
          key={"diligence-glossary"}
          title={"Glossary"}
          titleVariant="h3"
        >
          <Stack component="ul" spacing={2}>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Due Diligence (DD):
                </Typography>
                An in-depth examination and verification of a target
                company&apos;s business, assets, liabilities, and performance.
                It’s a risk assessment process undertaken by potential buyers
                before finalising an acquisition.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Data Room:</Typography>A
                secure online platform where sellers provide detailed
                documentation and information about their company for potential
                buyers to review.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Financial Due Diligence:
                </Typography>
                Scrutiny of the target&apos;s financial statements, cash flows,
                debts, assets, liabilities, and past financial performance.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Technical Due Diligence:
                </Typography>
                Assessment of the technology used by the target company,
                including software, architecture, IP, tech stack, scalability,
                and potential technical liabilities.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  IP (Intellectual Property) Due Diligence:
                </Typography>
                Examination of the target&apos;s IP portfolio, including
                patents, copyrights, trademarks, and any potential IP litigation
                risks.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Operational Due Diligence:
                </Typography>
                Review of the target&apos;s operational processes, supplier
                contracts, customer contracts, operational risks, and
                facilities.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Commercial Due Diligence:{" "}
                </Typography>
                Evaluation of the target&apos;s market positioning, competition,
                growth potential, and business model&apos;s viability.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Legal Due Diligence:
                </Typography>
                Examination of legal aspects including pending or potential
                lawsuits, legal compliance, employment contracts, and corporate
                structure.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Synergies Assessment:
                </Typography>
                An evaluation of potential cost savings and revenue growth
                resulting from the acquisition.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Material Adverse Change (MAC) Clause:
                </Typography>
                A provision that allows a buyer to withdraw from a deal if a
                significant negative event impacts the target before the deal
                closes.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Third-Party Vendors:
                </Typography>
                External entities that provide services or products to the
                target company. Their contracts and performance are often
                reviewed during DD.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  SPA (Sale & Purchase Agreement):
                </Typography>
                A document outlining the final terms of the sale of the company,
                including key negotiation outcomes such as the final price of
                acquisition.
              </Typography>
            </li>
          </Stack>
        </ExpandableRow>
        <ExpandableRow
          key={"digiligence-advice"}
          title={"Advice"}
          titleVariant="h3"
        >
          <Stack component="ul" spacing={2}>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Understanding the Importance of Due Diligence & relevant types
                  for tech:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Defining Due Diligence:
                    </Typography>
                    <Typography variant="body2">
                      This is a comprehensive investigation of the business by a
                      potential buyer to verify its assets, liabilities,
                      potential risks, and overall viability.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      The Objective:
                    </Typography>
                    <Typography variant="body2">
                      The goal is to ensure that there are no hidden issues or
                      liabilities that could adversely affect the value of the
                      business or the buyer&apos;s decision to proceed with the
                      transaction.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      The process:
                    </Typography>
                    <Typography variant="body2">
                      The due diligence process is usually handled through a
                      Virtual Data Room, where the seller will upload detailed
                      answers to your questions with any relevant documents
                      needed.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Financial Due Diligence:
                    </Typography>
                    <Typography variant="body2">
                      Scrutiny of your profit & loss statements, balance sheets,
                      cash flow statements, and other financial metrics.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Technical Due Diligence:
                    </Typography>
                    <Typography variant="body2">
                      This includes a deep dive into your tech stack, source
                      code quality, scalability of systems, cybersecurity
                      measures, and IP assets. Software documentation,
                      architecture reviews, and even code audits might be
                      required.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Operational Due Diligence:
                    </Typography>
                    <Typography variant="body2">
                      Examination of your company&apos;s operational procedures,
                      supply chains, customer contracts, and vendor agreements.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Legal Due Diligence:
                    </Typography>
                    <Typography variant="body2">
                      Inspection of all legal matters, including IP rights
                      (patents, trademarks, copyrights), employment contracts,
                      pending litigation, and regulatory compliance.
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Due Diligence queries & tech related advice:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>Q&amp;A:</Typography>
                    <Typography variant="body2">
                      This is your opportunity to ask any questions that may
                      have arisen during your initial review of the business and
                      relevant marketing materials, such as the Information
                      Memorandum. Make sure to be thorough in your analysis and
                      use this time to solve any issues
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Types of questions:
                    </Typography>
                    <Typography variant="body2">
                      Often the types of questions that arise are on details
                      that may not have been included in the Information
                      Memorandum or accompanying documents, such as a list of
                      key customers, full company ownership (capitalisation
                      table) or details around any key legal documentation.
                      Also, sometimes the information presented in the
                      Information Memorandum needs further explanation, such as
                      why a particular year has lower than usual revenue, or why
                      a key customer left the business.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Infrastructure & Scalability:
                    </Typography>
                    <Typography variant="body2">
                      Detail of the infrastructure the tech runs on. How easily
                      can it scale? If the company utilises cloud services,
                      which ones, and what&apos;s the cost structure?
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      IP Verification:
                    </Typography>
                    <Typography variant="body2">
                      Ensure all intellectual properties, whether they&apos;re
                      patents, trademarks, or copyrights, are in order and
                      registered under the company&apos;s name.
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Post Due Diligence:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Feedback Review:
                    </Typography>
                    <Typography variant="body2">
                      Once the due diligence process concludes, the seller may
                      ask for feedback. This could offer valuable insights for
                      the seller, regardless of whether the deal progresses or
                      not.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Signing the deal:
                    </Typography>
                    <Typography variant="body2">
                      Based on the findings, there might be negotiations on the
                      deal terms, price adjustments, or specific conditions set
                      before the sale. Finalise a price with the seller and sign
                      a SPA (Sale & Purchase Agreement or Share Purchase
                      Agreement)
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
          </Stack>
        </ExpandableRow>
      </Box>
    ),
  },

  {
    title: "Escrow and transfer",
    content: (
      <Box>
        <ExpandableRow
          key={"escrow-glossary"}
          title={"Glossary"}
          titleVariant="h3"
        >
          <Stack component="ul" spacing={2}>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Escrow:</Typography>A
                financial arrangement where a neutral third-party (the escrow
                service) temporarily holds and regulates payment of the funds
                required for two parties involved in a transaction. It ensures
                transactions are completed according to the agreed terms.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Escrow Service:
                </Typography>
                A trusted third-party entity, often a bank or legal firm, that
                holds an asset or funds on behalf of two other parties that are
                completing a transaction.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Escrow Duration:
                </Typography>
                The predetermined time period for which funds or assets are held
                in escrow before they are released, often based on meeting
                certain conditions or milestones.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Release Conditions:
                </Typography>
                The specific terms or milestones that must be met for the assets
                or funds held in escrow to be released to the receiving party.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Intellectual Property (IP) Transfer:
                </Typography>
                The process of transferring ownership of patents, trademarks,
                copyrights, and trade secrets from one entity to another.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Data Rooms:</Typography>
                Secure online repositories used to store and share confidential
                information, typically used during M&A due diligence to
                facilitate document sharing between buyer and seller.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Integration Process:
                </Typography>
                The steps and methodologies applied to ensure that the tech
                systems, software applications, and data of an acquired company
                combine and function cohesively with those of the acquiring
                company.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Regulatory & Compliance Checks:
                </Typography>
                The processes of ensuring that transferred assets or operations
                meet all relevant industry-specific regulations, legal
                standards, or certifications.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>Milestone:</Typography>A
                predetermined metric or event, used as a reference to measure
                progress, especially in terms of escrow release conditions in
                M&A transactions.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Data Migration:
                </Typography>
                The process of transferring data between storage types, formats,
                or computer systems, often a vital part of tech M&A when
                integrating systems.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Talent Transition:
                </Typography>
                The process of moving employees from the seller&apos;s entity to
                the buyer&apos;s, often involving changes in roles,
                responsibilities, and sometimes, company culture.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Post-Acquisition:
                </Typography>
                The period following the completion of an M&A transaction,
                focusing on integration, adjustment, and optimisation of
                combined operations and assets.
              </Typography>
            </li>
          </Stack>
        </ExpandableRow>
        <ExpandableRow key={"escrow-advice"} title={"Advice"} titleVariant="h3">
          <Stack component="ul" spacing={2}>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Understand the Role of Escrow in M&A and prepare terms:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>Escrow</Typography>
                    <Typography variant="body2">
                      Escrow is a neutral third-party service that holds funds
                      or assets until predetermined conditions are met. In M&A,
                      it acts as a protective measure to ensure that both
                      parties uphold their end of the agreement.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Choose a reputable escrow service:
                    </Typography>
                    <Typography variant="body2">
                      Given the complexities of tech transactions, always select
                      an escrow provider experienced in tech M&A. They&apos;ll
                      be familiar with the unique challenges and intricacies
                      such transactions present.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Determine escrow duration:
                    </Typography>
                    <Typography variant="body2">
                      While standard escrow periods might range from 12-24
                      months, tech deals, especially those involving software or
                      IP, may require different durations. It&apos;s essential
                      to assess potential risks, like undiscovered software bugs
                      or IP infringements, when determining this timeframe.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Clarify release conditions:
                    </Typography>
                    <Typography variant="body2">
                      Define clear terms for releasing escrow funds. This could
                      range from meeting specific operational milestones, like
                      maintaining a certain number of active users, to more
                      technical criteria, such as software performance
                      standards.
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Transfer of assets & employees:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Transfer of Intellectual Property (IP):
                    </Typography>
                    <Typography variant="body2">
                      Tech M&amp;A often involves the transfer of significant
                      IP. Ensure all IP (patents, copyrights, trademarks, and
                      trade secrets) is correctly identified, documented, and
                      transferred. Engage IP attorneys to ensure the accurate
                      and legal transfer of these assets.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Transfer of software & digital assets:
                    </Typography>
                    <Typography variant="body2">
                      When transferring software, source codes, databases, or
                      other digital assets, ensure a secure and systematic
                      handover process. Consider using data rooms or other
                      secure transfer methods. Also, ensure all access
                      credentials, API keys, and other essential information are
                      shared and updated.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Transfer of employees:
                    </Typography>
                    <Typography variant="body2">
                      Tech companies often derive their value from their talent.
                      Ensure there&apos;s a clear plan for transitioning
                      employees. Address any concerns about company culture,
                      roles, and compensation. Also, clarify any changes in
                      terms of software tools, work protocols, or company
                      policies.
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Post-Acquisition Tech Integration:
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Integration:
                    </Typography>
                    <Typography variant="body2">
                      If you as the buyer have existing tech infrastructure,
                      plan the integration process. Address potential software
                      conflicts, streamline tools and platforms, and ensure that
                      data migration happens securely and efficiently.
                    </Typography>
                  </li>
                </Box>
                <Box component="ul" my={2}>
                  <li>
                    <Typography sx={{ fontWeight: 600 }}>
                      Continuous communication:
                    </Typography>
                    <Typography variant="body2">
                      Keep channels of communication open. The post-acquisition
                      period can be fraught with unexpected challenges,
                      especially in tech where rapid changes are the norm. Both
                      parties should remain proactive in addressing issues,
                      offering support, and facilitating a smooth transition.
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <Typography sx={{ fontWeight: 600 }}>
                  Regulatory & Compliance checks
                </Typography>
                <Box component="ul" my={2}>
                  <li>
                    <Typography variant="body2">
                      Especially relevant for tech businesses, ensure that the
                      transferred assets comply with all industry-specific
                      regulations, be it data protection standards, software
                      licensing terms, or other relevant standards.{" "}
                    </Typography>
                  </li>
                </Box>
              </Typography>
            </li>
          </Stack>
        </ExpandableRow>
      </Box>
    ),
  },
];

export const YourAcquisitionsBuyer = () => {
  const [newListings, setNewListings] = useState<IStartup[]>([]);
  const { getNewListings, progress, setBuyerStep, addBuyerStep } =
    useYourAcquisitions();
  const navigate = useNavigate();
  const { user } = useAppContext();
  const { showModal } = useModal();

  const promoListing = usePromoListings();

  const showVideoModal = () => {
    const modal = showModal(VideoModal, {
      url: "https://www.loom.com/embed/59724d5a31c646ac92e1ca57097c7cfa",
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const showHotDealModal = (promoListing: number) => {
    const modal = showModal(HotDealModal, {
      companyId: promoListing,
      onCancel: () => {
        modal.hide();
      },
    });
  };

  const handleEducationStep = (step: number) => {
    const hasStep = progress?.find((p) => p.step === step);
    if (hasStep) {
      setBuyerStep(step, !hasStep.value);
    } else {
      addBuyerStep(step, true);
    }
  };

  const getListings = async () => {
    await getNewListings().then((res) => setNewListings(res));
  };

  useEffect(() => {
    if (IS_PROD && promoListing) showHotDealModal(promoListing);
    getListings();
  }, []);

  return (
    <Box sx={{ background: "#F8F8F8" }}>
      <Header>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Typography variant="h2" sx={{ fontSize: { xs: 16, md: 24 } }}>
            Your Acquisition Hub
          </Typography>
        </Box>
      </Header>
      <Container maxWidth="lg" sx={{ paddingY: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            marginBottom: 3,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: "32px",
              paddingLeft: { xs: 0, md: 4 },
              marginBottom: { xs: 2, md: 0 },
            }}
          >
            {greetingsPhrase()}, {user.first_name}{" "}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ position: "relative", zIndex: 1 }}
          >
            <Button
              id="refer-and-earn-btn"
              variant="roundgradient"
              onClick={() =>
                navigate(RoutePaths.REGISTER_ACQUIRER + "/specific")
              }
            >
              Refine Profile
            </Button>
          </Stack>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 3,
                backgroundImage: "url(/images/YourAcquisitionTop.png)",
                backgroundPosition: "right",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                height: "max-content",
                paddingX: 4,
                paddingY: 6,
              }}
            >
              <Box
                sx={{
                  background:
                    "linear-gradient(90deg, #FFF 53.7%, rgba(255, 255, 255, 0.00) 100%)",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: 28,
                    color: "common.black",
                    marginBottom: 2,
                    lineHeight: "36px",
                    background:
                      "linear-gradient(104.51deg, #C72A8E 3.97%, #7815DA 97.22%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }}
                >
                  Everything you need for
                  <br /> your acquisition
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                  Welcome to your personalised Acquisition Hub. <br />
                  Here you can find tools, tips and expert support all in one
                  place.
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={4} marginTop={1}>
              <Grid item xs={12} md={6}>
                <Paper
                  sx={{
                    minHeight: "220px",
                    padding: 4,
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography variant="h3" sx={{ marginBottom: 1 }}>
                      Introductory Video
                    </Typography>
                    <Typography variant="body2" sx={{ color: "common.gray" }}>
                      Watch this welcome video for business owners led by our
                      CEO explaining how we can help you during the M&A process
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      className="watch-video-btn"
                      onClick={() => showVideoModal()}
                      variant="roundoutlinedgradient"
                    >
                      <span>Watch Video</span>
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  sx={{
                    minHeight: "220px",
                    padding: 4,
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography variant="h3" sx={{ marginBottom: 1 }}>
                      Use Escrow to make an offer?
                    </Typography>
                    <Typography variant="body2" sx={{ color: "common.gray" }}>
                      If you have make an offer and are unsure what to do next,
                      then let one of your expect M&A advisors help you through
                      the escrow process.
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      href={JOE_CALENDLY_LINK}
                      target="_blank"
                      rel="noreferrer"
                      className="watch-video-btn"
                      variant="roundgradient"
                    >
                      <span>Book a call</span>
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 3,
                padding: 2,
                position: "relative",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <TrolleyIcon /> New listings
              </Box>
              <Divider sx={{ marginY: 2 }} />
              <Box
                component="ul"
                sx={{
                  padding: 0,
                  display: "flex",
                  gap: 2,
                  flexDirection: "column",
                  listStyle: "none",
                  justifyContent: "center",
                }}
              >
                {newListings.map((x, _index) => (
                  <li key={_index}>
                    <TinyListing {...x} />
                  </li>
                ))}
              </Box>
              <Box textAlign="center">
                <Button
                  href="/dashboard"
                  variant="roundoutlinedgradient"
                  sx={{ display: "inline-block" }}
                >
                  <span>View more</span>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <ReferBanner />

        <Box marginTop={4} padding={4}>
          <Typography variant="h2" marginBottom={1}>
            Your interactive guide for the end to end exit process
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "common.gray", fontWeight: 500 }}
          >
            Utilise this checklist and Foundy’s suite of digital tools and
            experts to complete a rewarding exit.
            <br /> Need expert help? Upgrade to one of our packages.
          </Typography>
        </Box>
        {steps.map((item, index) => (
          <ExpandableRow
            key={index}
            step={index + 1}
            title={item.title}
            effect={
              progress.every((x) => x.value === true) && progress.length === 5
            }
            stepProgress={progress.find((x) => x.step === index + 1)?.value}
            stepAction={() => handleEducationStep(index + 1)}
          >
            {item.content}
          </ExpandableRow>
        ))}

        <Box
          sx={{
            marginTop: 4,
            padding: 4,
            background: "white",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" sx={{ marginBottom: 1 }}>
            Frequently asked questions
          </Typography>
          <Typography variant="body2" sx={{ maxWidth: 800 }}>
            If you have any questions or issues about your acquisition process
            or our platform view our FAQ page below. If the answer is not there,
            please do not hesitate to ask us via instant message or email
            (hello@foundy.com). We are here to help you and continually learn
            how we can best support our clients.
          </Typography>
          <Button
            href={FAQ_PAGE_LINK}
            target="_blank"
            variant="roundgradient"
            sx={{ marginTop: 3 }}
          >
            View answers to FAQs
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

import { Dashboard } from "../containers/Dashboard";
import { BasicInformation } from "../containers/SignUp";
import { RoutePaths, RouteType } from "./enums";
// import AcUnitIcon from "@mui/icons-material/AcUnit";
import SettingsIcon from "@mui/icons-material/Settings";
import { Messages } from "../containers/Messages";
import { Login } from "../containers/Login";
import { CompanyView } from "../containers/CompanyView";
import { YourAcquisitions } from "../containers/YourAcquisitions";
import { YourListings } from "../containers/YourListings/YourListings";
import { Settings } from "../containers/Settings";
import { RegisterStart } from "../containers/Register";
import { ForgotPassword } from "../containers/Login/ForgotPassword";
import { UserType } from "../../common/types";
import {
  HeartIcon,
  ListIcon,
  MoneyIcon,
  WorkIcon,
} from "../../common/assets/icons";
import { TrolleyIcon } from "../../common/assets/icons/TrolleyIcon";
import { AdvisorIcon } from "../../common/assets/icons/AdvisorIcon";
import { MessageIcon } from "../../common/assets/icons/MessageIcon";
import { DocumentIcon } from "../../common/assets/icons/DocumentIcon";
import { CompletedIcon } from "../../common/assets/icons/CompletedIcon";
import { UserIcon } from "../../common/assets/icons/User";

import { ResetPassword } from "../containers/Login/ResetPassword";
import { Management } from "../containers/Management";
import { PlansPage } from "../containers/PlansPage";
import { PlansIcon } from "../../common/assets/icons/PlansIcon";
import { Referral } from "../containers/Referral";
import { SellerBasic } from "../containers/ProfileCompletion/Seller/SellerBasic";
import { SellerDataRoom } from "../containers/ProfileCompletion/Seller/SellerDataRoom";
import { TestimonialIcon } from "../../common/assets/icons/TestimonialIcon";

import BuyerPreferences from "../containers/ProfileCompletion/Buyer/BuyerPreferences";
import BuyerType from "../containers/ProfileCompletion/Buyer/BuyerType";
import BuyerBudget from "../containers/ProfileCompletion/Buyer/BuyerBudget";
import BuyerCompleted from "../containers/ProfileCompletion/Buyer/BuyerCompleted";
import AdditionalInfo from "../containers/ProfileCompletion/Buyer/AdditionalInfo";
import ProBuyerInterests from "../containers/ProfileCompletion/Buyer/BuyerInterests";
import BasicBuyerCompleted from "../containers/ProfileCompletion/Buyer/BasicBuyerCompleted";

import { SellerCompleted } from "../containers/ProfileCompletion/Seller/SellerCompleted";
import { SellerFunding } from "../containers/ProfileCompletion/Seller/SellerFunding";
import BusinessFootprint from "../containers/ProfileCompletion/Seller/BusinessFootprint";
import BusinessInformation from "../containers/ProfileCompletion/Seller/BusinessInformation";
import FinancialInformation from "../containers/ProfileCompletion/Seller/FinancialInformation";
import Founder from "../containers/ProfileCompletion/Seller/Founder";
import { FUNDRAISING_LINK } from "./consts";

export interface MenuItemsInterface {
  icon?: any;
  label: string;
  path: string;
  type: string;
  target?: React.ComponentType;
  hideFrom?: UserType;
  checkUser?: boolean;
  fields?: string[]; // the fields include the required data in order to mark the step as complete
}
export interface SideMenuItemsInterface {
  [x: string]: { disableGradient?: boolean; items: MenuItemsInterface[] };
}

export const Menus: SideMenuItemsInterface = {
  [RoutePaths.REGISTER]: {
    disableGradient: false,
    items: [
      {
        label: "Basic Details",
        path: RoutePaths.REGISTER + "/basic",
        type: RouteType.PUBLIC,
        target: RegisterStart,
        checkUser: true,
        fields: ["first_name", "last_name", "phone_number", "location"],
      },
    ],
  },
  [RoutePaths.REGISTER_ACQUIRER]: {
    disableGradient: true,
    items: [
      {
        label: "Buyer Type",
        path: RoutePaths.REGISTER_ACQUIRER + "/specific",
        type: RouteType.PUBLIC,
        target: BuyerType,
        checkUser: true,
        fields: ["buyerFull.role", "buyerFull.buyer_type"],
      },
      {
        label: "Buyer Preferences",
        path: RoutePaths.REGISTER_ACQUIRER + "/categories",
        type: RouteType.PUBLIC,
        target: BuyerPreferences,
        checkUser: true,
        fields: [
          "buyerFull.startup_business_types",
          "buyerFull.buyer_location",
        ],
      },
      {
        label: "Acquisition Budget",
        path: RoutePaths.REGISTER_ACQUIRER + "/budget",
        type: RouteType.PUBLIC,
        target: BuyerBudget,
        checkUser: true,
        fields: [
          "buyerFull.valuation",
          "buyerFull.trailing_profit",
          "buyerFull.trailing_revenue",
          "buyerFull.debt_finance",
        ],
      },
      {
        label: "Additional Info",
        path: RoutePaths.REGISTER_ACQUIRER + "/additional-info",
        type: RouteType.PUBLIC,
        target: AdditionalInfo,
        checkUser: true,
        fields: ["buyerFull.social_media", "buyerFull.description"],
      },
      {
        icon: UserIcon,
        label: "Basic Buyer Completed",
        path: RoutePaths.REGISTER_ACQUIRER + "/basic-completed",
        type: RouteType.PUBLIC,
        target: BasicBuyerCompleted,
      },
      {
        label: "Interests",
        path: RoutePaths.REGISTER_ACQUIRER + "/pro-buyer-interests",
        type: RouteType.PUBLIC,
        target: ProBuyerInterests,
        checkUser: true,
        fields: ["buyerFull.keywords"],
      },
      {
        icon: CompletedIcon,
        label: "Completed",
        path: RoutePaths.REGISTER_ACQUIRER + "/completed",
        type: RouteType.PUBLIC,
        target: BuyerCompleted,
      },
    ],
  },
  [RoutePaths.REGISTER_SELLER]: {
    disableGradient: true,
    items: [
      {
        label: "Basic Seller Information",
        path: RoutePaths.REGISTER_SELLER + "/:id/basic",
        type: RouteType.PUBLIC,
        target: SellerBasic,
        checkUser: true,
        fields: ["website_url", "business_type", "business_categories"],
      },
      {
        label: "Private Business Information",
        path: RoutePaths.REGISTER_SELLER + "/:id/footprint",
        type: RouteType.PUBLIC,
        target: BusinessFootprint,
        checkUser: true,
        fields: ["date_founded"],
      },
      {
        label: "Public Business Information",
        path: RoutePaths.REGISTER_SELLER + "/:id/business-info",
        type: RouteType.PUBLIC,
        target: BusinessInformation,
        checkUser: true,
        fields: [
          "description",
          "startup_team_size",
          "operating_regions",
          "key_assets_new",
        ],
      },
      {
        label: "Financial Information",
        path: RoutePaths.REGISTER_SELLER + "/:id/financial-info",
        type: RouteType.PUBLIC,
        target: FinancialInformation,
        checkUser: true,
        fields: [
          "business_models",
          "annual_profit",
          "annual_revenue",
          // "startups.cvl", // FIXME: find a way to iterate over the array
        ],
      },
      {
        label: "Founder",
        path: RoutePaths.REGISTER_SELLER + "/:id/founder",
        type: RouteType.PUBLIC,
        target: Founder,
        checkUser: true,
        fields: ["reason", "asking_price"],
      },
      {
        label: "Data Room",
        path: RoutePaths.REGISTER_SELLER + "/:id/data-room",
        type: RouteType.PUBLIC,
        target: SellerDataRoom,
        checkUser: true,
        fields: ["files"],
      },
      {
        icon: CompletedIcon,
        label: "Completed",
        path: RoutePaths.REGISTER_SELLER + "/:id/completed",
        type: RouteType.PUBLIC,
        target: SellerCompleted,
      },
    ],
  },
  "/buyer": {
    items: [
      {
        icon: WorkIcon,
        label: "Your Acquisition",
        path: "/your-acquisition",
        type: RouteType.PUBLIC,
        target: YourAcquisitions,
      },
      {
        icon: TrolleyIcon,
        label: "Marketplace",
        path: RoutePaths.DASHBOARD,
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: MessageIcon,
        label: "Messages",
        path: "/messages",
        type: RouteType.PUBLIC,
        target: Messages,
      },
      {
        icon: AdvisorIcon,
        label: "Advisors",
        path: "https://foundy.com/advisory/sellers",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: MessageIcon,
        label: "Private Data Room",
        path: RoutePaths.REGISTER_SELLER + "/funding",
        type: RouteType.PUBLIC,
        target: SellerFunding,
        hideFrom: UserType.Buyer,
      },
      {
        icon: DocumentIcon,
        label: "Doc Templates",
        path: "https://foundy.trumpet.app/pods/63ece8e13bd3e0ac24d8c39a/live",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: HeartIcon,
        label: "Give Feedback",
        path: "intercom",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: SettingsIcon,
        label: "Settings",
        path: RoutePaths.REGISTER_SELLER + "/funding",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: TestimonialIcon,
        label: "Testimonials",
        path: "https://testimonials.foundy.com",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
    ],
  },
  "/company": {
    items: [
      {
        icon: WorkIcon,
        label: "Your Acquisition",
        path: "/your-acquisition",
        type: RouteType.PUBLIC,
        target: YourAcquisitions,
      },
      {
        icon: TrolleyIcon,
        label: "Marketplace",
        path: RoutePaths.DASHBOARD,
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: MessageIcon,
        label: "Messages",
        path: "/messages",
        type: RouteType.PUBLIC,
        target: Messages,
      },
      {
        icon: AdvisorIcon,
        label: "Advisors",
        path: "https://foundy.com/advisory/sellers",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: MessageIcon,
        label: "Private Data Room",
        path: RoutePaths.REGISTER_SELLER + "/funding",
        type: RouteType.PUBLIC,
        target: SellerFunding,
        hideFrom: UserType.Buyer,
      },

      {
        icon: DocumentIcon,
        label: "Doc Templates",
        path: "https://foundy.trumpet.app/pods/63ece8e13bd3e0ac24d8c39a/live",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },

      {
        icon: HeartIcon,
        label: "Give Feedback",
        path: "intercom",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: SettingsIcon,
        label: "Settings",
        path: RoutePaths.REGISTER_SELLER + "/funding",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: TestimonialIcon,
        label: "Testimonials",
        path: "https://testimonials.foundy.com",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
    ],
  },
  [RoutePaths.DASHBOARD]: {
    items: [
      {
        icon: WorkIcon,
        label: "Your Acquisition",
        path: "/your-acquisition",
        type: RouteType.PUBLIC,
        target: YourAcquisitions,
      },
      {
        icon: ListIcon,
        label: "Your Listings",
        path: "/your-listings",
        type: RouteType.PUBLIC,
        target: YourListings,
        hideFrom: UserType.Buyer,
      },
      {
        icon: TrolleyIcon,
        label: "Marketplace",
        path: RoutePaths.DASHBOARD,
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: MoneyIcon,
        label: "Raise Capital",
        path: FUNDRAISING_LINK,
        type: RouteType.PUBLIC,
      },
      {
        icon: MessageIcon,
        label: "Messages",
        path: "/messages",
        type: RouteType.PUBLIC,
        target: Messages,
      },
      {
        icon: PlansIcon,
        label: "Subscription Plans",
        path: RoutePaths.PLANS,
        type: RouteType.AUTHENTICATED,
        target: PlansPage,
        hideFrom: UserType.Seller,
      },
      {
        icon: DocumentIcon,
        label: "Doc Templates",
        path: "https://foundy.trumpet.app/pods/63ece8e13bd3e0ac24d8c39a/live",
        type: RouteType.PUBLIC,
      },
      {
        icon: UserIcon,
        label: "Edit profile",
        path: `${RoutePaths.REGISTER_ACQUIRER}/specific`,
        type: RouteType.AUTHENTICATED,
        target: PlansPage,
        hideFrom: UserType.Seller,
      },
      {
        icon: SettingsIcon,
        label: "Settings",
        path: RoutePaths.SETTINGS,
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: HeartIcon,
        label: "Give Feedback",
        path: "intercom",
        type: RouteType.PUBLIC,
        target: SellerFunding,
      },
      {
        icon: AdvisorIcon,
        label: "Access Expert Advisory",
        path: "https://foundy.com/advisory/sellers",
        type: RouteType.PUBLIC,
        hideFrom: UserType.Buyer,
      },
      {
        icon: TestimonialIcon,
        label: "Testimonials",
        path: "https://testimonials.foundy.com",
        type: RouteType.PUBLIC,
      },
    ],
  },
};

export const routes = [
  {
    title: "Login",
    path: RoutePaths.LOGIN,
    type: RouteType.PUBLIC,
    target: Login,
    menu: undefined,
  },
  {
    title: "Register",
    path: "/register",
    type: RouteType.PUBLIC,
    target: RegisterStart,
    menu: undefined,
  },
  {
    title: "Register",
    path: "/referral",
    type: RouteType.PUBLIC,
    target: Referral,
    menu: undefined,
  },
  {
    title: "Register",
    path: "/register/:id?",
    type: RouteType.PUBLIC,
    target: RegisterStart,
    menu: undefined,
  },
  {
    title: "Forgot Password",
    path: RoutePaths.FORGOT_PASSWORD,
    type: RouteType.PUBLIC,
    target: ForgotPassword,
  },
  {
    title: "Reset your password",
    path: RoutePaths.RESET_PASSWORD,
    type: RouteType.PUBLIC,
    target: ResetPassword,
  },
  {
    title: "Management",
    path: "/management",
    type: RouteType.AUTHENTICATED,
    target: Management,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    title: "Plans",
    path: "/plans",
    type: RouteType.AUTHENTICATED,
    target: PlansPage,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    title: "Register - Basic Details",
    path: RoutePaths.REGISTER + "/basic",
    type: RouteType.PUBLIC,
    target: BasicInformation,
    menu: Menus[RoutePaths.REGISTER],
    exact: true,
  },
  ...Menus[RoutePaths.REGISTER_ACQUIRER].items.map(
    ({ label, icon, ...rest }) => ({
      ...rest,
      title: label,
      menu: Menus[RoutePaths.REGISTER_ACQUIRER],
      exact: true,
    }),
  ),
  ...Menus[RoutePaths.REGISTER_SELLER].items.map(
    ({ label, icon, ...rest }) => ({
      ...rest,
      title: label,
      menu: Menus[RoutePaths.REGISTER_SELLER],
      exact: true,
    }),
  ),
  {
    path: RoutePaths.REGISTER_ACQUIRER + "/completed",
    type: RouteType.PUBLIC,
    target: BuyerCompleted,
    menu: Menus[RoutePaths.REGISTER_ACQUIRER],
    exact: true,
  },
  {
    path: RoutePaths.REGISTER_SELLER + "/completed",
    type: RouteType.PUBLIC,
    target: SellerCompleted,
    menu: Menus[RoutePaths.REGISTER_ACQUIRER],
    exact: true,
  },
  {
    title: "Dashboard",
    path: RoutePaths.DASHBOARD,
    type: RouteType.AUTHENTICATED,
    target: Dashboard,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    title: "Settings",
    path: RoutePaths.SETTINGS,
    type: RouteType.AUTHENTICATED,
    target: Settings,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    title: "Your Acquisitions",
    path: "/your-acquisition",
    type: RouteType.AUTHENTICATED,
    target: YourAcquisitions,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    icon: ListIcon,
    label: "Your Listings",
    path: "/your-listings",
    type: RouteType.AUTHENTICATED,
    target: YourListings,
    hideFrom: UserType.Buyer,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    title: "Messages",
    path: "/messages",
    type: RouteType.AUTHENTICATED,
    target: Messages,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    title: "Company View",
    path: "/company/:id",
    type: RouteType.PUBLIC,
    target: CompanyView,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    title: "Subscription Plans",
    path: RoutePaths.PLANS,
    type: RouteType.AUTHENTICATED,
    target: PlansPage,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    title: "Register - Seller",
    path: RoutePaths.REGISTER_SELLER,
    type: RouteType.PUBLIC,
    target: SellerBasic,
    menu: Menus[RoutePaths.DASHBOARD],
  },
  {
    title: "Register - Buyer Type",
    path: RoutePaths.REGISTER_ACQUIRER,
    type: RouteType.PUBLIC,
    target: BuyerType,
    menu: Menus[RoutePaths.DASHBOARD],
  },

  {
    title: "",
    path: "*",
    type: RouteType.PUBLIC,
    target: Login,
    menu: undefined,
  },
];

import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
// import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";

interface ModalProps extends DialogProps {
  onCancel: () => void;
  url?: string;
}

export const VideoModal = (props: ModalProps) => {
  return (
    <Dialog fullWidth maxWidth="lg" {...props} scroll="body">
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Box sx={{ cursor: "pointer" }} onClick={() => props.onCancel()}>
          <CloseIcon />
        </Box>
      </DialogTitle>

      <DialogContent sx={{ textAlign: "center" }}>
        <iframe
          src={props.url}
          allowFullScreen
          style={{ border: 0, width: "100%", minHeight: "70vh" }}
        />
        {/* <ReactPlayer width="100%" height="100%" url={props.url} /> */}
      </DialogContent>
    </Dialog>
  );
};
